import firmLocations from './modules/firmLocations';
import cookieWatcher from './modules/cookieWatcher';
import googleMapsConsentEventRunner from './modules/googleMapsConsentEventRunner';

window.addEventListener('load', firmLocations());

window.initGoogleMaps = () => {
    window.dispatchEvent(new Event('initMap'));
};

window.addEventListener('load', cookieWatcher());
window.googleMapsConsentEventRunner = googleMapsConsentEventRunner;
