export default function cookieWatcher() {
    const googleMapsConsentBoxes = [...document.querySelectorAll('.js-google-maps-consent')];

    function loadGoogleMapsJs() {
        const jsScript = document.getElementById('js-maps-script');
        if (!jsScript) {
            return;
        }

        jsScript.setAttribute('src', jsScript.getAttribute('data-src'));
    }

    function hideCookieConsentBoxes(boxes) {
        boxes.forEach((googleMapsConsentBox) => {
            if (window.BorlabsCookie.checkCookieConsent('googlemaps')) {
                // eslint-disable-next-line no-param-reassign
                googleMapsConsentBox.style.display = 'none';
            }
        });
    }
    function createGoogleMapsConsent(e) {
        e.preventDefault();
        window.BorlabsCookie.addConsent('external-media', 'googlemaps');
    }

    googleMapsConsentBoxes.forEach((googleMapsConsentBox) => {
        const button = googleMapsConsentBox.querySelector('.js-google-maps-consent-button');
        button.addEventListener('click', createGoogleMapsConsent);
    });

    document.body.addEventListener('googlemapsconsent', (event) => {
        if (event.detail.acceptedGoogleMaps) {
            hideCookieConsentBoxes(googleMapsConsentBoxes);
            loadGoogleMapsJs();
        }
    });
}
