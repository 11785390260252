import mapStyles from './mapStyles.json';

export default () => {
    let map;
    let markers;
    let locationsHeight = 0;
    const locationsList = document.querySelector('.js-locations-list');
    const locationListItems = document.querySelectorAll('.js-locations-item');
    const scrollUpButton = document.querySelector('.js-locations-scroll-up');
    const scrollDownButton = document.querySelector('.js-locations-scroll-down');

    const eventsStart = [
        'mousedown',
        'touchstart',
    ];

    const eventsStop = [
        'mouseup',
        'touchend',
    ];

    const eventsScroll = eventsStart.concat(eventsStop);

    let timeOut = 0;

    if (!locationsList) {
        return;
    }

    function getLocationInfo(location) {
        const address = `${location.street} ${location.number}<br/>${location.zip} ${location.city}`;
        const telNmail = location.phone && location.mail;
        const tel = location.phone ? `Tel.: ${location.phone}` : '';
        const mail = location.mail ? `E-Mail: ${location.mail}` : '';
        const contact = `${tel}${telNmail ? '<br>' : ''}${mail}`;

        return `<div class="locations__info">
            <span class="locations__headline">${location.city || location.country}</span>
            <p class="locations__paragraph">${address}</p>
            ${telNmail ? `<p class="locations__paragraph">${contact}</p>` : ''}
            </div>`;
    }

    function togglePin(event) {
        const locationItem = event.target;
        const {locationId} = locationItem.dataset;
        const marker = markers[locationId];
        // eslint-disable-next-line no-undef
        google.maps.event.trigger(marker, 'click');
    }

    function initMap() {
        const mapElement = document.getElementById('google-map');
        mapElement.style.display = 'block';
        // eslint-disable-next-line no-undef
        map = new google.maps.Map(mapElement, {
            center: {lat: 51.1642292, lng: 10.4541194},
            disableDefaultUI: true,
            zoom: 6,
            styles: mapStyles,
        });

        markers = [];

        // eslint-disable-next-line no-undef
        const infoWindow = new google.maps.InfoWindow();
        // eslint-disable-next-line no-undef
        const latlngbounds = new google.maps.LatLngBounds();

        if (window.profileLocations) {
            window.profileLocations.forEach((location) => {
                const latlng = {
                    lat: location.lat,
                    lng: location.lng,
                };

                latlngbounds.extend(latlng);

                // eslint-disable-next-line no-undef
                const marker = new google.maps.Marker({
                    position: latlng,
                    map,
                    // eslint-disable-next-line no-undef
                    icon: PIN_IMAGE,
                    title: location.city || location.country,
                    location_id: location.id,
                });

                markers[location.id] = marker;

                marker.addListener('click', () => {
                    const locationItems = locationsList.querySelectorAll('.js-locations-item');
                    locationItems.forEach((item) => {
                        item.classList.remove('acitve');
                    });
                    infoWindow.close();
                    infoWindow.setContent(getLocationInfo(location));
                    infoWindow.open(marker.getMap(), marker);
                    const id = marker.get('location_id');
                    const locationItem = locationsList.querySelector(`[data-location-id="${id}"]`);
                    locationsList.scrollTop = locationItem.offsetTop;
                    locationItem.classList.add('active');
                });
            });
        }

        if (latlngbounds.getNorthEast().equals(latlngbounds.getSouthWest())) {
            // eslint-disable-next-line no-undef
            const extendPoint = new google.maps.LatLng(
                latlngbounds.getNorthEast().lat() + 0.01,
                latlngbounds.getNorthEast().lng() + 0.01,
            );
            latlngbounds.extend(extendPoint);
        }
        map.fitBounds(latlngbounds);
    }

    function scrollFun(event) {
        if (eventsStart.includes(event.type)) {
            timeOut = setInterval(() => {
                if (event.target.classList.contains('js-locations-scroll-up')
                    && locationsList.scrollTop !== 0) {
                    locationsList.scrollBy(0, -100);
                } else if (event.target.classList.contains('js-locations-scroll-down')
                    && locationsList.scrollHeight > (locationsList.offsetHeight + locationsList.scrollTop)) {
                    locationsList.scrollBy(0, 100);
                } else {
                    clearInterval(timeOut);
                }
            }, 100);
        }

        if (eventsStop.includes(event.type)) {
            clearInterval(timeOut);
        }
    }

    window.addEventListener('initMap', initMap);

    locationListItems.forEach((item) => {
        item.addEventListener('click', togglePin);
        locationsHeight += item.offsetHeight;
    });

    if (scrollUpButton && scrollDownButton) {
        if (locationsHeight <= locationsList.offsetHeight) {
            scrollDownButton.classList.add('hide');
        }

        locationsList.addEventListener('scroll', () => {
            if (locationsList.scrollTop === 0) {
                scrollUpButton.classList.add('hide');
            } else {
                scrollUpButton.classList.remove('hide');
            }
            if (locationsList.scrollTop === (locationsList.scrollHeight - locationsList.offsetHeight)) {
                scrollDownButton.classList.add('hide');
            } else {
                scrollDownButton.classList.remove('hide');
            }
        });

        eventsScroll.forEach((event) => {
            scrollUpButton.addEventListener(event, scrollFun);
        });

        eventsScroll.forEach((event) => {
            scrollDownButton.addEventListener(event, scrollFun);
        });
    }
};
